<script>
import { reactive } from 'vue';

export default {
  setup() {
    const formData = reactive({
      contactNumber: '', // 合同协议号
      destCountry: '', // 最终目地国
      shipperName: '', // 境内发货人
      consigneeName: '', // 境外收货人
      orderDate: [], // 查询开始时间
      status: '0', // 状态
      productName: '', // 产品名称
    });
    // 查询结果展示数据
    const tableData = reactive({
      data: [
        // { 样式
        //     id: 1,
        //     orderDate: '2016-05-02',
        //     contractNum: 'INV_234',
        //     factoryName: '客户名称',
        //     consigneeName: '收货人名称',
        //     productName: '产品名称，可以多个',
        //     destCountry: 'USA',
        //     netWt: '1300.00',
        //     grossWt: '1400.00',
        //     carton: '500',
        //     stepState: '审核中',
        //     mobile: '13420398253',
        //     authorized: 'xxxxxxxxxx',
        //     failedReason: '驳回原因',
        //     payDate: '2024-05-01',
        //   },
      ],
      maskDivState: {
        status: false, // 详情遮罩开关
        options: {}, // 存放uuid等信息
      },
    });
    return {
      tableData,
      formData,
    };
  },
};
</script>
