import { ElMessageBox } from 'element-plus';
// 设置时间选择器的函数
export default class DateFunc {
  constructor(formata) {
    this.formData = formata;
  }

  // 设置下单时间选择范围（3年内）,一个用来判断该日期是否被禁用的函数，
  //  time.getTime() < new Date().getTime() - 3 * 365 * 24 * 3600 * 1000 || time.getTime() > Date.now()

  disabledDateFunc = (time) => {
    const dateNow = new Date();
    const bl = time.getTime() > dateNow.setFullYear(dateNow.getFullYear() - 3) && time.getTime() < new Date().valueOf();
    return !bl;
  };

  // 下单时间失去焦点
  orderDateBlurFun = () => {
    // 不能大于3个月时间,范围在3年内数据,不能超过现在
    const maxYear = 3;
    const maxMounth = 3;
    this.formData.orderDate[1] = new Date(Number(this.formData.orderDate[1])).setHours(23, 59, 59, 59); // 设置成23:59:59分
    const { orderDate } = this.formData;
    const startDate = new Date(Number(orderDate[0])); // 开始时间
    const endDate = Number(orderDate[1]); // 结束时间
    const formatDate = new Date().setHours(23, 59, 59, 59);// 当前时间
    const overMaxDate = Number(orderDate[1]) > formatDate || Number(orderDate[0]) > formatDate; // 大于当前时间
    const overMounth = startDate.setMonth(startDate.getMonth() + 3) < endDate;// 最大限制90天
    const setBestYear = new Date(formatDate).setFullYear(new Date(formatDate).getFullYear() - 3);// 设置成3年前的时间
    const overYear = Number(orderDate[0]) < setBestYear || Number(orderDate[1]) < setBestYear;// 年限不超3年

    if (overMounth || overYear || overMaxDate) {
      ElMessageBox.alert(overYear ? `请选择近${maxYear}年内数据!` : `请勿超出当日数据或选择${maxMounth}个月范围数据!`, {
        title: '提示',
        type: 'warning',
        showClose: false,
        confirmButtonText: '确定',
        closeOnClickModal: false, // 点击蒙层取消
        closeOnPressEscape: false, // 禁止按ESC
      }).then((res) => {
        if (res === 'confirm') {
          // 30天时间
          const resetDate = new Date();
          this.formData.orderDate = [
            new Date(resetDate.getFullYear(), resetDate.getMonth() - 1, resetDate.getDate(), 0, 0, 0).valueOf().toString(),
            new Date(resetDate.getFullYear(), resetDate.getMonth(), resetDate.getDate(), 23, 59, 59).valueOf().toString(),
          ];
        }
      });
    }
  }
}
