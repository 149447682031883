<template>
  <div class="queryForm">
    <el-form :model="auditFormData.formData" label-width="105px" :inline="false" size="normal" ref="queryFormRef"
      @submit.prevent>
      <el-form-item label="合同协议号:" prop="contactNumber">
        <el-input v-model="auditFormData.formData.contactNumber" placeholder="请输入合同协议号" size="small" clearable />
      </el-form-item>
      <el-form-item label="最终目的国:" prop="destCountry">
        <el-select v-model="auditFormData.formData.destCountry" id="destCountry" size="small" filterable
          default-first-option placeholder="请选择要查询最终目的国" @blur="auditSelectClassFuncByCountry.selectBlurFunc"
          @change="auditSelectClassFuncByCountry.selectChangeFunc"
          @input="auditSelectClassFuncByCountry.selectFilterFunc">
          <el-option v-for="item in auditFormData.optionsByCountry" :key="item.value" :label="item.tagName"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="境内发货人:" prop="shipperName">
        <el-input v-model="auditFormData.formData.shipperName" placeholder="请输入境内发货人" size="small" clearable />
      </el-form-item>
      <el-form-item label="境外买家:" prop="consigneeName">
        <el-input v-model="auditFormData.formData.consigneeName" placeholder="请输入境外买家" size="small" clearable />
      </el-form-item>
      <el-form-item label="下单时间:" prop="orderDate">
        <div class="orderDate">
          <el-date-picker size="small" v-model="auditFormData.formData.orderDate" type="daterange"
            start-placeholder="开始日期" end-placeholder="结束日期" :default-time="defaultTimeFunc"
            :disabled-date="dateFunc.disabledDateFunc" format="YYYY-MM-DD" unlink-panels value-format="x"
            :clearable="false" @blur="dateFunc.orderDateBlurFun"></el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="状态:" prop="status">
        <el-select v-model="auditFormData.formData.status" size="small" id="status" filterable default-first-option
          @blur="auditSelectClassFuncByStatus.selectBlurFunc" @change="auditSelectClassFuncByStatus.selectChangeFunc"
          @input="auditSelectClassFuncByStatus.selectFilterFunc" placeholder="请选择当前状态">
          <el-option v-for="item in auditFormData.optionsByStatus" :key="item.value" :label="item.tagName"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品名称:" prop="productName">
        <el-input v-model="auditFormData.formData.productName" placeholder="输入所需查询产品名称" size="small" clearable />
      </el-form-item>
      <el-form-item>
        <el-row>
          <el-button-group>
            <el-button type="success" native-type="submit" @click="auditButtonClass.submitFunc">查询</el-button>
            <el-button type="warning" @click="auditButtonClass.resetFunc">重置</el-button>
          </el-button-group>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  onMounted,
  reactive,
  ref,
} from 'vue';
import countryOptions from '../../configs/country';
import statusOptions from '../../configs/stepStatusAry';
import AuditSelectClassFunc from './auditSelectCalss';
import DateFunc from './auditDateClass';
import AuditButtonClass from './auditButtonClass';

export default {
  name: 'AuditQueryForm',
  props: [
    'formData', // 表单查询数据
    'tableData', // 表单查询结果数据
  ],
  setup(props) {
    const auditFormData = reactive({
      formData: props.formData,
      tableData: props.tableData,
      optionsByCountry: [...countryOptions], // 目的地options
      optionsByStatus: [...statusOptions.stepStatusAry], // 状态options
    });

    // 实例一个下拉函数（country）
    const auditSelectClassFuncByCountry = new AuditSelectClassFunc(auditFormData.formData, auditFormData.optionsByCountry, (blurGetValue) => {
      auditFormData.formData.destCountry = blurGetValue; // 回调，失去焦点获取到的值
    });
    // 实例一个下拉函数（status）
    const auditSelectClassFuncByStatus = new AuditSelectClassFunc(auditFormData.formData, auditFormData.optionsByStatus, (blurGetValue) => {
      auditFormData.formData.status = blurGetValue;// 回调，失去焦点获取到的值
    });

    // 起始与结束时刻
    const resetDate = new Date();
    const defaultTimeFunc = ref([
      new Date(resetDate.getFullYear(), resetDate.getMonth() - 1, resetDate.getDate(), 0, 0, 0).valueOf().toString(),
      new Date(resetDate.getFullYear(), resetDate.getMonth(), resetDate.getDate(), 23, 59, 59).valueOf().toString(),
    ]);

    // 设置日期默认值
    auditFormData.formData.orderDate = defaultTimeFunc;
    // 实例一个日期函数（date)
    const dateFunc = new DateFunc(auditFormData.formData);

    // 实例按钮事件
    const auditButtonClass = new AuditButtonClass(auditFormData.formData, auditFormData.tableData);
    onMounted(() => {
      // 用户加载完成当前页，执行向后台查询数据
      auditButtonClass.submitFunc();
    });

    return {
      auditFormData,
      defaultTimeFunc,
      auditSelectClassFuncByCountry,
      auditSelectClassFuncByStatus,
      dateFunc,
      auditButtonClass,
    };
  },
};
</script>
<style lang="scss" scoped>
.mycard {
  padding: $base * 0.5 !important;
}

// 错误提示信息向上提升
:deep(.el-form-item__error) {
  padding-top: 0px;
}

.queryForm {
  padding: $base $base $base * 0.3 $base;

  :deep(.el-form) {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1150px) {
      .el-form-item {
        width: 50% !important;

        &:nth-child(2n) {
          .el-form-item__content {
            margin-right: 0 !important;
          }
        }

        &:nth-last-child(2) {
          width: 50%;
        }

        .el-form-item__content {
          margin-right: 15px !important;
        }
      }
    }

    .el-form-item {
      margin-bottom: 10px !important;
      width: 33.33%;

      &:nth-last-child(2) {
        width: 66.66%;
      }

      &:last-child {
        .el-form-item__content {
          margin-right: 0 !important;
        }

        .el-row>.el-button-group {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          >button {
            width: 49%;
          }
        }
      }

      .el-form-item__content {
        margin-right: 15px;
      }

      &:nth-child(3n) {
        .el-form-item__content {
          margin-right: 0;
        }
      }

      .el-select {
        width: 100%;
      }
    }
  }

  .orderDate {
    width: 100%;

    :deep(.el-date-editor) {
      width: 100%;
    }
  }
}
</style>
