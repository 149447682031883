export default class AuditFunc {
  constructor(formData, options, callBack) {
    this.formData = formData;
    this.backOptions = [...options]; // 保留一份原始options
    this.options = options; // 操作的当前数组
    this.inputVal = '';
    this.timeOut = null;// 延时处理
    this.isChange = false; // select下拉选择值动作
    this.callBack = callBack; // 设置值的回调函数
  }

  // 以下为下拉选择器
  selectBlurFunc = () => {
    if (this.isChange || this.inputVal === '') {
      return;
    }
    // 判断options中的值有多少条
    if (this.options.length > 0) {
      // 取第一条,回调让用户自行设备对应元素值
      this.callBack(this.options[0].value);
    }

    // 还原options中的值
    this.options = [...this.backOptions];
    this.inputVal = '';// 还原数据，为下次准备
  }

  // 按回车键选中值后函数
  selectChangeFunc = () => {
    this.isChange = true;
    this.options = [...this.backOptions];// 还原数据options
  }

  // 用户输入值
  selectFilterFunc = (queryStr) => {
    this.isChange = false;// 初始化下拉选择没有此动作
    const { id } = queryStr.target;
    const inputValue = document.getElementById(id).value;
    this.inputVal = inputValue; // 如果用户什么没有输入保持上次的数据

    clearTimeout(this.timeOut);
    const reg = new RegExp(inputValue, 'i');// 不区分大小写
    this.timeOut = setTimeout(() => {
      const res = this.backOptions.filter((item) => {
        // 正则匹配
        if (item.tagName.search(reg) !== -1) {
          return item;
        }
        return '';
      });
      this.options = [...res];
    }, 100);
  }
}
