import {
  ElMessageBox, ElMessage,
} from 'element-plus';
import MyAxios from '../../utils/axios/index_v2';

export default class AuditButtonClass {
  constructor(maskDivState) {
    this.maskDivState = maskDivState;
  }

  /**
   * 通过---按钮函数
   * @param {string} idx 唯一识别号
   * @param {*} stepState 审核文本信息
   * @returns 是否通过向后台请求数据
   */
  agreeClickFunc = (idx, stepState) => {
    // 当前状态为'核审不通过'，则不能操作此按钮
    if (stepState !== '审核中') {
      // 当前状态已是审核不通过，则无需驳回
      ElMessageBox.alert('当前状态非"审核中",不能操作此按钮', '提示');
      return;
    }

    ElMessageBox.confirm('确定通过吗？', '提示', {
      type: 'warning',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false, // 点击蒙层取消
      closeOnPressEscape: false, // 禁止按ESC
    }).then(() => {
      // 通过让后台更新数据
      MyAxios().initAxios({
        url: '/audit_update',
        method: 'POST',
        data: {
          uuid: idx,
          handleFuncName: 'agreeHandel',
        },
      }).then((res) => {
        // 成功接收数据
        if (res.code === 200) {
          ElMessage({
            type: 'success',
            message: res.results,
            duration: 2000,
          });
        } else {
          // 返回null数据
          ElMessage({
            type: 'warning',
            message: res.results,
            duration: 2000,
          });
        }
      }).catch(() => {
        // 未发送成功
        ElMessage({
          type: 'warning',
          message: '连接失败',
          duration: 1500,
        });
      });
    }).catch(() => {
      ElMessage({
        type: 'warning',
        message: '你取消了当前操作',
        duration: 1500,
      });
    });
  }

  /**
   * 未通过--按钮函数
   * @param {string} idx 操作id
   * @param {*} stepState 审核文本信息
   * @returns 是否通过向后台请求数据
   */
  disagreeClickFunc = (idx, stepState) => {
    if (stepState !== '审核中') {
      // 当前状态已是审核不通过，则无需驳回
      ElMessageBox.alert('当前状态非"审核中",此次操作无效', '提示');
      return;
    }

    const msgStr = `
    1001: '申报要素填写错误',
    1002: '禁止一般贸易代理出口产品',
    1003: '填写内容逻辑有误',
    1004: '生产厂家内容填写错误',
    1005: '境外收货人内容填写错误',
    1006: '其他错误',`;

    ElMessageBox.prompt(`请填写驳回代码,${msgStr}`, '提示', {
      inputPlaceholder: '请录入驳回原由',
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^100[1-6]/, // 仅限于1001-1006区间代码
      inputErrorMessage: '请填写正确的驳回代码',
      type: 'warning',
    }).then(({ value }) => {
      // 驳回让后台更新数据
      MyAxios().initAxios({
        url: '/audit_update',
        method: 'POST',
        data: {
          reasonMessageCode: Number(value), // 错误代码
          uuid: idx,
          handleFuncName: 'disagreeHandel',
        },
      }).then((res) => {
        if (res.code === 200) {
          ElMessage({
            type: 'success',
            message: res.results,
            duration: 2000,
          });
        } else {
          ElMessage({
            type: 'warning',
            message: res.results,
            duration: 2000,
          });
        }
      }).catch(() => {
        ElMessage({
          type: 'warning',
          message: '连接失败',
          duration: 1500,
        });
      });
    }).catch(() => {
      ElMessage({
        type: 'warning',
        message: '你取消了当前操作',
        duration: 1500,
      });
    });
  }

  // 查询看文件
  showClickFunc = (idx) => {
    this.maskDivState.options.id = idx.toString();// 赋值给后台id
    this.maskDivState.status = true; // 显示遮罩层
  }
}
