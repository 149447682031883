<template>
  <div class="customsdocs">
    <div class="mycardbox">
      <my-card>
        <template #mycard>
          <QueryForm :formData="formData" :tableData="data" />
        </template>
      </my-card>
    </div>
    <div class="mycardbox">
      <my-card>
        <template #mycard>
          <TableForm :tableData="data" :maskDivState="maskDivState" />
        </template>
      </my-card>
    </div>
  </div>
</template>

<script>

import AuditByModel from '../components/audit/AuditByModel.vue';
import MyCard from '../components/util/MyCard.vue';
import TableForm from '../components/audit/AuditTableForm.vue';
import QueryForm from '../components/audit/AuditQueryForm.vue';

export default {
  name: 'audit',
  components: {
    MyCard,
    QueryForm,
    TableForm,
  },

  setup() {
    const {
      formData, // 查询表单字段
      tableData, // 查询结果字段
    } = AuditByModel.setup();
    const { data, maskDivState } = tableData;
    return {
      formData,
      data,
      maskDivState,
    };
  },
};
</script>
<style lang="scss" scoped>
$viewHeight: calc(100vh - 100px - 20px);

.customsdocs {
  height: $viewHeight;
  width: 100%;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;

  .mycardbox {
    margin-bottom: 1px;

    &:last-child {
      .mycard {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: $radiusSize !important;
        border-bottom-right-radius: $radiusSize !important;
      }
    }

    &:first-child {
      .mycard {
        border-top-left-radius: $radiusSize !important;
        border-top-right-radius: $radiusSize !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  .mycard {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
  }
}
</style>
