export default ([
  { value: 'AFG', label: '阿富汗', tagName: '101-阿富汗(AFG)--Afghanistan' },
  { value: 'ALB', label: '阿尔巴尼亚', tagName: '313-阿尔巴尼亚(ALB)--Albania' },
  { value: 'ATA', label: '南极洲', tagName: '701-南极洲(ATA)--Antarctica' },
  { value: 'DZA', label: '阿尔及利亚', tagName: '201-阿尔及利亚(DZA)--Algeria' },
  { value: 'ASM', label: '美属萨摩亚', tagName: '699-美属萨摩亚(ASM)--American Samoa' },
  { value: 'AND', label: '安道尔', tagName: '314-安道尔(AND)--Andorra' },
  { value: 'AGO', label: '安哥拉', tagName: '202-安哥拉(AGO)--Angola' },
  { value: 'ATG', label: '安提瓜和巴布达', tagName: '401-安提瓜和巴布达(ATG)--Antigua and Barbuda' },
  { value: 'AZE', label: '阿塞拜疆', tagName: '339-阿塞拜疆(AZE)--Azerbaijan' },
  { value: 'ARG', label: '阿根廷', tagName: '402-阿根廷(ARG)--Argentina' },
  { value: 'AUS', label: '澳大利亚', tagName: '601-澳大利亚(AUS)--Australia' },
  { value: 'AUT', label: '奥地利', tagName: '315-奥地利(AUT)--Austria' },
  { value: 'BHS', label: '巴哈马', tagName: '404-巴哈马(BHS)--Bahamas (the)' },
  { value: 'BHR', label: '巴林', tagName: '102-巴林(BHR)--Bahrain' },
  { value: 'BGD', label: '孟加拉', tagName: '103-孟加拉(BGD)--Bangladesh' },
  { value: 'ARM', label: '亚美尼亚', tagName: '338-亚美尼亚(ARM)--Armenia' },
  { value: 'BRB', label: '巴巴多斯', tagName: '405-巴巴多斯(BRB)--Barbados' },
  { value: 'BEL', label: '比利时', tagName: '301-比利时(BEL)--Belgium' },
  { value: 'BMU', label: '百慕大', tagName: '504-百慕大(BMU)--Bermuda' },
  { value: 'BTN', label: '不丹', tagName: '104-不丹(BTN)--Bhutan' },
  { value: 'BOL', label: '玻利维亚', tagName: '408-玻利维亚(BOL)--Bolivia (Plurinational State of)' },
  { value: 'BIH', label: '波斯尼亚和黑塞哥维那', tagName: '355-波斯尼亚和黑塞哥维那(BIH)--Bosnia and Herzegovina' },
  { value: 'BWA', label: '博茨瓦纳', tagName: '204-博茨瓦纳(BWA)--Botswana' },
  { value: 'BVT', label: '布维岛', tagName: '326-布维岛(BVT)--Bouvet Island' },
  { value: 'BRA', label: '巴西', tagName: '410-巴西(BRA)--Brazil' },
  { value: 'BLZ', label: '伯利兹', tagName: '406-伯利兹(BLZ)--Belize' },
  { value: 'IOT', label: '英属印度洋领地', tagName: '299-英属印度洋领地(IOT)--British Indian Ocean Territory (the)' },
  { value: 'SLB', label: '所罗门群岛', tagName: '613-所罗门群岛(SLB)--Solomon Islands' },
  { value: 'VGB', label: '英属维尔京群岛', tagName: '446-英属维尔京群岛(VGB)--Virgin Islands (British)' },
  { value: 'BRN', label: '文莱', tagName: '105-文莱(BRN)--Brunei Darussalam' },
  { value: 'BGR', label: '保加利亚', tagName: '316-保加利亚(BGR)--Bulgaria' },
  { value: 'MMR', label: '缅甸', tagName: '106-缅甸(MMR)--Myanmar' },
  { value: 'BDI', label: '布隆迪', tagName: '205-布隆迪(BDI)--Burundi' },
  { value: 'BLR', label: '白俄罗斯', tagName: '340-白俄罗斯(BLR)--Belarus' },
  { value: 'KHM', label: '柬埔寨', tagName: '107-柬埔寨(KHM)--Cambodia' },
  { value: 'CMR', label: '喀麦隆', tagName: '206-喀麦隆(CMR)--Cameroon' },
  { value: 'CAN', label: '加拿大', tagName: '501-加拿大(CAN)--Canada' },
  { value: 'CPV', label: '佛得角', tagName: '208-佛得角(CPV)--Cabo Verde' },
  { value: 'CYM', label: '开曼群岛', tagName: '411-开曼群岛(CYM)--Cayman Islands (the)' },
  { value: 'CAF', label: '中非', tagName: '209-中非(CAF)--Central African Republic (the)' },
  { value: 'LKA', label: '斯里兰卡', tagName: '134-斯里兰卡(LKA)--Sri Lanka' },
  { value: 'TCD', label: '乍得', tagName: '211-乍得(TCD)--Chad' },
  { value: 'CHL', label: '智利', tagName: '412-智利(CHL)--Chile' },
  { value: 'CHN', label: '中国', tagName: '142-中国(CHN)--China' },
  { value: 'TWN', label: '中国台湾', tagName: '143-中国台湾(TWN)--Taiwan (Province of China)' },
  { value: 'CXR', label: '圣诞岛', tagName: '699-圣诞岛(CXR)--Christmas Island' },
  { value: 'CCK', label: '科科斯（基林）群岛', tagName: '699-科科斯（基林）群岛(CCK)--Cocos (Keeling) Islands (the)' },
  { value: 'COL', label: '哥伦比亚', tagName: '413-哥伦比亚(COL)--Colombia' },
  { value: 'COM', label: '科摩罗', tagName: '212-科摩罗(COM)--Comoros (the)' },
  { value: 'MYT', label: '马约特', tagName: '259-马约特(MYT)--Mayotte' },
  { value: 'COG', label: '刚果（布）', tagName: '213-刚果（布）(COG)--Congo (the)' },
  { value: 'COD', label: '刚果（金）', tagName: '252-刚果（金）(COD)--Congo (the Democratic Republic of the)' },
  { value: 'COK', label: '库克群岛', tagName: '602-库克群岛(COK)--Cook Islands (the)' },
  { value: 'CRI', label: '哥斯达黎加', tagName: '415-哥斯达黎加(CRI)--Costa Rica' },
  { value: 'HRV', label: '克罗地亚', tagName: '351-克罗地亚(HRV)--Croatia' },
  { value: 'CUB', label: '古巴', tagName: '416-古巴(CUB)--Cuba' },
  { value: 'CYP', label: '塞浦路斯', tagName: '108-塞浦路斯(CYP)--Cyprus' },
  { value: 'CZE', label: '捷克', tagName: '352-捷克(CZE)--Czechia' },
  { value: 'BEN', label: '贝宁', tagName: '203-贝宁(BEN)--Benin' },
  { value: 'DNK', label: '丹麦', tagName: '302-丹麦(DNK)--Denmark' },
  { value: 'DMA', label: '多米尼克', tagName: '414-多米尼克(DMA)--Dominica' },
  { value: 'DOM', label: '多米尼加', tagName: '418-多米尼加(DOM)--Dominican Republic (the)' },
  { value: 'ECU', label: '厄瓜多尔', tagName: '419-厄瓜多尔(ECU)--Ecuador' },
  { value: 'SLV', label: '萨尔瓦多', tagName: '440-萨尔瓦多(SLV)--El Salvador' },
  { value: 'GNQ', label: '赤道几内亚', tagName: '216-赤道几内亚(GNQ)--Equatorial Guinea' },
  { value: 'ETH', label: '埃塞俄比亚', tagName: '217-埃塞俄比亚(ETH)--Ethiopia' },
  { value: 'ERI', label: '厄立特里亚', tagName: '258-厄立特里亚(ERI)--Eritrea' },
  { value: 'EST', label: '爱沙尼亚', tagName: '334-爱沙尼亚(EST)--Estonia' },
  { value: 'FRO', label: '法罗群岛', tagName: '357-法罗群岛(FRO)--Faroe Islands (the)' },
  { value: 'FLK', label: '福克兰群岛(马尔维纳斯)', tagName: '499-福克兰群岛(马尔维纳斯)(FLK)--Falkland Islands (the) [Malvinas]' },
  { value: 'SGS', label: '南乔治亚岛和南桑德韦奇岛', tagName: '499-南乔治亚岛和南桑德韦奇岛(SGS)--South Georgia and the South Sandwich Islands' },
  { value: 'FJI', label: '斐济', tagName: '603-斐济(FJI)--Fiji' },
  { value: 'FIN', label: '芬兰', tagName: '318-芬兰(FIN)--Finland' },
  { value: 'ALA', label: '阿兰群岛（波罗的海中芬兰所属群岛）', tagName: '399-阿兰群岛（波罗的海中芬兰所属群岛）(ALA)--Åland Islands' },
  { value: 'FRA', label: '法国', tagName: '305-法国(FRA)--France' },
  { value: 'GUF', label: '法属圭亚那', tagName: '420-法属圭亚那(GUF)--French Guiana' },
  { value: 'PYF', label: '法属波利尼西亚', tagName: '623-法属波利尼西亚(PYF)--French Polynesia' },
  { value: 'ATF', label: '法属南部领地', tagName: '299-法属南部领地(ATF)--French Southern Territories (the)' },
  { value: 'DJI', label: '吉布提', tagName: '214-吉布提(DJI)--Djibouti' },
  { value: 'GAB', label: '加蓬', tagName: '218-加蓬(GAB)--Gabon' },
  { value: 'GEO', label: '格鲁吉亚', tagName: '337-格鲁吉亚(GEO)--Georgia' },
  { value: 'GMB', label: '冈比亚', tagName: '219-冈比亚(GMB)--Gambia (the)' },
  { value: 'PSE', label: '巴勒斯坦', tagName: '128-巴勒斯坦(PSE)--Palestine, State of' },
  { value: 'DEU', label: '德国', tagName: '304-德国(DEU)--Germany' },
  { value: 'GHA', label: '加纳', tagName: '220-加纳(GHA)--Ghana' },
  { value: 'GIB', label: '直布罗陀', tagName: '320-直布罗陀(GIB)--Gibraltar' },
  { value: 'KIR', label: '基里巴斯', tagName: '618-基里巴斯(KIR)--Kiribati' },
  { value: 'GRC', label: '希腊', tagName: '310-希腊(GRC)--Greece' },
  { value: 'GRL', label: '格陵兰', tagName: '503-格陵兰(GRL)--Greenland' },
  { value: 'GRD', label: '格林纳达', tagName: '421-格林纳达(GRD)--Grenada' },
  { value: 'GLP', label: '瓜德罗普', tagName: '422-瓜德罗普(GLP)--Guadeloupe' },
  { value: 'GUM', label: '关岛', tagName: '699-关岛(GUM)--Guam' },
  { value: 'GTM', label: '危地马拉', tagName: '423-危地马拉(GTM)--Guatemala' },
  { value: 'GIN', label: '几内亚', tagName: '221-几内亚(GIN)--Guinea' },
  { value: 'GUY', label: '圭亚那', tagName: '424-圭亚那(GUY)--Guyana' },
  { value: 'HTI', label: '海地', tagName: '425-海地(HTI)--Haiti' },
  { value: 'HMD', label: '赫德岛和麦克唐纳岛', tagName: '699-赫德岛和麦克唐纳岛(HMD)--Heard Island and McDonald Islands' },
  { value: 'VAT', label: '梵蒂冈', tagName: '356-梵蒂冈(VAT)--Holy See (the)' },
  { value: 'HND', label: '洪都拉斯', tagName: '426-洪都拉斯(HND)--Honduras' },
  { value: 'HKG', label: '中国香港', tagName: '110-中国香港(HKG)--Hong Kong' },
  { value: 'HUN', label: '匈牙利', tagName: '321-匈牙利(HUN)--Hungary' },
  { value: 'ISL', label: '冰岛', tagName: '322-冰岛(ISL)--Iceland' },
  { value: 'IND', label: '印度', tagName: '111-印度(IND)--India' },
  { value: 'IDN', label: '印度尼西亚', tagName: '112-印度尼西亚(IDN)--Indonesia' },
  { value: 'IRN', label: '伊朗', tagName: '113-伊朗(IRN)--Iran (Islamic Republic of)' },
  { value: 'IRQ', label: '伊拉克', tagName: '114-伊拉克(IRQ)--Iraq' },
  { value: 'IRL', label: '爱尔兰', tagName: '306-爱尔兰(IRL)--Ireland' },
  { value: 'ISR', label: '以色列', tagName: '115-以色列(ISR)--Israel' },
  { value: 'ITA', label: '意大利', tagName: '307-意大利(ITA)--Italy' },
  { value: 'CIV', label: '科特迪瓦', tagName: "223-科特迪瓦(CIV)--Côte d'Ivoire" },
  { value: 'JAM', label: '牙买加', tagName: '427-牙买加(JAM)--Jamaica' },
  { value: 'JPN', label: '日本', tagName: '116-日本(JPN)--Japan' },
  { value: 'KAZ', label: '哈萨克斯坦', tagName: '145-哈萨克斯坦(KAZ)--Kazakhstan' },
  { value: 'JOR', label: '约旦', tagName: '117-约旦(JOR)--Jordan' },
  { value: 'KEN', label: '肯尼亚', tagName: '224-肯尼亚(KEN)--Kenya' },
  { value: 'PRK', label: '朝鲜', tagName: "109-朝鲜(PRK)--Korea (the Democratic People's Republic of)" },
  { value: 'KOR', label: '韩国', tagName: '133-韩国(KOR)--Korea (the Republic of)' },
  { value: 'KWT', label: '科威特', tagName: '118-科威特(KWT)--Kuwait' },
  { value: 'KGZ', label: '吉尔吉斯斯坦', tagName: '146-吉尔吉斯斯坦(KGZ)--Kyrgyzstan' },
  { value: 'LAO', label: '老挝', tagName: "119-老挝(LAO)--Lao People's Democratic Republic (the)" },
  { value: 'LBN', label: '黎巴嫩', tagName: '120-黎巴嫩(LBN)--Lebanon' },
  { value: 'LSO', label: '莱索托', tagName: '255-莱索托(LSO)--Lesotho' },
  { value: 'LVA', label: '拉脱维亚', tagName: '335-拉脱维亚(LVA)--Latvia' },
  { value: 'LBR', label: '利比里亚', tagName: '225-利比里亚(LBR)--Liberia' },
  { value: 'LBY', label: '利比亚', tagName: '226-利比亚(LBY)--Libya' },
  { value: 'LIE', label: '列支敦士登', tagName: '323-列支敦士登(LIE)--Liechtenstein' },
  { value: 'LTU', label: '立陶宛', tagName: '336-立陶宛(LTU)--Lithuania' },
  { value: 'LUX', label: '卢森堡', tagName: '308-卢森堡(LUX)--Luxembourg' },
  { value: 'MAC', label: '中国澳门', tagName: '121-中国澳门(MAC)--Macao' },
  { value: 'MDG', label: '马达加斯加', tagName: '227-马达加斯加(MDG)--Madagascar' },
  { value: 'MWI', label: '马拉维', tagName: '228-马拉维(MWI)--Malawi' },
  { value: 'MYS', label: '马来西亚', tagName: '122-马来西亚(MYS)--Malaysia' },
  { value: 'MDV', label: '马尔代夫', tagName: '123-马尔代夫(MDV)--Maldives' },
  { value: 'MLI', label: '马里', tagName: '229-马里(MLI)--Mali' },
  { value: 'MLT', label: '马耳他', tagName: '324-马耳他(MLT)--Malta' },
  { value: 'MTQ', label: '马提尼克', tagName: '428-马提尼克(MTQ)--Martinique' },
  { value: 'MRT', label: '毛里塔尼亚', tagName: '230-毛里塔尼亚(MRT)--Mauritania' },
  { value: 'MUS', label: '毛里求斯', tagName: '231-毛里求斯(MUS)--Mauritius' },
  { value: 'MEX', label: '墨西哥', tagName: '429-墨西哥(MEX)--Mexico' },
  { value: 'MCO', label: '摩纳哥', tagName: '325-摩纳哥(MCO)--Monaco' },
  { value: 'MNG', label: '蒙古', tagName: '124-蒙古(MNG)--Mongolia' },
  { value: 'MDA', label: '摩尔多瓦', tagName: '343-摩尔多瓦(MDA)--Moldova (the Republic of)' },
  { value: 'MNE', label: '黑山共和国', tagName: '359-黑山共和国(MNE)--Montenegro' },
  { value: 'MSR', label: '蒙特塞拉特', tagName: '430-蒙特塞拉特(MSR)--Montserrat' },
  { value: 'MAR', label: '摩洛哥', tagName: '232-摩洛哥(MAR)--Morocco' },
  { value: 'MOZ', label: '莫桑比克', tagName: '233-莫桑比克(MOZ)--Mozambique' },
  { value: 'OMN', label: '阿曼', tagName: '126-阿曼(OMN)--Oman' },
  { value: 'NAM', label: '纳米比亚', tagName: '234-纳米比亚(NAM)--Namibia' },
  { value: 'NRU', label: '瑙鲁', tagName: '606-瑙鲁(NRU)--Nauru' },
  { value: 'NPL', label: '尼泊尔', tagName: '125-尼泊尔(NPL)--Nepal' },
  { value: 'NLD', label: '荷兰', tagName: '309-荷兰(NLD)--Netherlands (the)' },
  { value: 'CUW', label: '库腊索岛', tagName: '417-库腊索岛(CUW)--Curaçao' },
  { value: 'ABW', label: '阿鲁巴', tagName: '403-阿鲁巴(ABW)--Aruba' },
  { value: 'SXM', label: '荷属圣马丁岛', tagName: '449-荷属圣马丁岛(SXM)--Sint Maarten (Dutch part)' },
  { value: 'BES', label: '博内尔岛、圣尤斯特歇斯岛和萨巴岛', tagName: '449-博内尔岛、圣尤斯特歇斯岛和萨巴岛(BES)--Bonaire, Sint Eustatius and Saba' },
  { value: 'NCL', label: '新喀里多尼亚', tagName: '607-新喀里多尼亚(NCL)--New Caledonia' },
  { value: 'VUT', label: '瓦努阿图', tagName: '608-瓦努阿图(VUT)--Vanuatu' },
  { value: 'NZL', label: '新西兰', tagName: '609-新西兰(NZL)--New Zealand' },
  { value: 'NIC', label: '尼加拉瓜', tagName: '431-尼加拉瓜(NIC)--Nicaragua' },
  { value: 'NER', label: '尼日尔', tagName: '235-尼日尔(NER)--Niger (the)' },
  { value: 'NGA', label: '尼日利亚', tagName: '236-尼日利亚(NGA)--Nigeria' },
  { value: 'NIU', label: '纽埃', tagName: '699-纽埃(NIU)--Niue' },
  { value: 'NFK', label: '诺福克岛', tagName: '610-诺福克岛(NFK)--Norfolk Island' },
  { value: 'NOR', label: '挪威', tagName: '326-挪威(NOR)--Norway' },
  { value: 'MNP', label: '北马里亚纳自由联邦', tagName: '699-北马里亚纳自由联邦(MNP)--Northern Mariana Islands (the)' },
  { value: 'UMI', label: '美国本土外小岛屿', tagName: '699-美国本土外小岛屿(UMI)--United States Minor Outlying Islands (the)' },
  { value: 'FSM', label: '密克罗尼西亚(联邦)', tagName: '620-密克罗尼西亚(联邦)(FSM)--Micronesia (Federated States of)' },
  { value: 'MHL', label: '马绍尔群岛', tagName: '621-马绍尔群岛(MHL)--Marshall Islands (the)' },
  { value: 'PLW', label: '帕劳', tagName: '622-帕劳(PLW)--Palau' },
  { value: 'PAK', label: '巴基斯坦', tagName: '127-巴基斯坦(PAK)--Pakistan' },
  { value: 'PAN', label: '巴拿马', tagName: '432-巴拿马(PAN)--Panama' },
  { value: 'PNG', label: '巴布亚新几内亚', tagName: '611-巴布亚新几内亚(PNG)--Papua New Guinea' },
  { value: 'PRY', label: '巴拉圭', tagName: '433-巴拉圭(PRY)--Paraguay' },
  { value: 'PER', label: '秘鲁', tagName: '434-秘鲁(PER)--Peru' },
  { value: 'PHL', label: '菲律宾', tagName: '129-菲律宾(PHL)--Philippines (the)' },
  { value: 'PCN', label: '皮特凯恩', tagName: '699-皮特凯恩(PCN)--Pitcairn' },
  { value: 'POL', label: '波兰', tagName: '327-波兰(POL)--Poland' },
  { value: 'PRT', label: '葡萄牙', tagName: '311-葡萄牙(PRT)--Portugal' },
  { value: 'GNB', label: '几内亚比绍', tagName: '222-几内亚比绍(GNB)--Guinea-Bissau' },
  { value: 'TLS', label: '东帝汶', tagName: '144-东帝汶(TLS)--Timor-Leste' },
  { value: 'PRI', label: '波多黎各', tagName: '435-波多黎各(PRI)--Puerto Rico' },
  { value: 'QAT', label: '卡塔尔', tagName: '130-卡塔尔(QAT)--Qatar' },
  { value: 'REU', label: '留尼汪', tagName: '237-留尼汪(REU)--Réunion' },
  { value: 'ROU', label: '罗马尼亚', tagName: '328-罗马尼亚(ROU)--Romania' },
  { value: 'RUS', label: '俄罗斯联邦', tagName: '344-俄罗斯联邦(RUS)--Russian Federation (the)' },
  { value: 'RWA', label: '卢旺达', tagName: '238-卢旺达(RWA)--Rwanda' },
  { value: 'BLM', label: '加勒比海圣巴特岛', tagName: '499-加勒比海圣巴特岛(BLM)--Saint Barthélemy' },
  { value: 'SHN', label: '圣赫勒拿', tagName: '299-圣赫勒拿(SHN)--Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KNA', label: '圣基茨和尼维斯', tagName: '447-圣基茨和尼维斯(KNA)--Saint Kitts and Nevis' },
  { value: 'AIA', label: '安圭拉', tagName: '499-安圭拉(AIA)--Anguilla' },
  { value: 'LCA', label: '圣卢西亚', tagName: '437-圣卢西亚(LCA)--Saint Lucia' },
  { value: 'MAF', label: '圣马丁(法国)', tagName: '438-圣马丁(法国)(MAF)--Saint Martin (French part)' },
  { value: 'SPM', label: '圣皮埃尔和密克隆', tagName: '448-圣皮埃尔和密克隆(SPM)--Saint Pierre and Miquelon' },
  { value: 'VCT', label: '圣文森特和格林纳丁斯', tagName: '439-圣文森特和格林纳丁斯(VCT)--Saint Vincent and the Grenadines' },
  { value: 'SMR', label: '圣马力诺', tagName: '329-圣马力诺(SMR)--San Marino' },
  { value: 'STP', label: '圣多美和普林西比', tagName: '239-圣多美和普林西比(STP)--Sao Tome and Principe' },
  { value: 'SAU', label: '沙特阿拉伯', tagName: '131-沙特阿拉伯(SAU)--Saudi Arabia' },
  { value: 'SEN', label: '塞内加尔', tagName: '240-塞内加尔(SEN)--Senegal' },
  { value: 'SRB', label: '塞尔维亚共和国', tagName: '358-塞尔维亚共和国(SRB)--Serbia' },
  { value: 'SYC', label: '塞舌尔', tagName: '241-塞舌尔(SYC)--Seychelles' },
  { value: 'SLE', label: '塞拉利昂', tagName: '242-塞拉利昂(SLE)--Sierra Leone' },
  { value: 'SGP', label: '新加坡', tagName: '132-新加坡(SGP)--Singapore' },
  { value: 'SVK', label: '斯洛伐克', tagName: '353-斯洛伐克(SVK)--Slovakia' },
  { value: 'VNM', label: '越南', tagName: '141-越南(VNM)--Viet Nam' },
  { value: 'SVN', label: '斯洛文尼亚', tagName: '350-斯洛文尼亚(SVN)--Slovenia' },
  { value: 'SOM', label: '索马里', tagName: '243-索马里(SOM)--Somalia' },
  { value: 'ZAF', label: '南非', tagName: '244-南非(ZAF)--South Africa' },
  { value: 'ZWE', label: '津巴布韦', tagName: '254-津巴布韦(ZWE)--Zimbabwe' },
  { value: 'ESP', label: '西班牙', tagName: '312-西班牙(ESP)--Spain' },
  { value: 'SSD', label: '南苏丹', tagName: '260-南苏丹(SSD)--South Sudan' },
  { value: 'SDN', label: '苏丹', tagName: '246-苏丹(SDN)--Sudan (the)' },
  { value: 'ESH', label: '西撒哈拉', tagName: '245-西撒哈拉(ESH)--Western Sahara*' },
  { value: 'SUR', label: '苏里南', tagName: '441-苏里南(SUR)--Suriname' },
  { value: 'SJM', label: '斯瓦尔巴岛和扬马延岛', tagName: '399-斯瓦尔巴岛和扬马延岛(SJM)--Svalbard and Jan Mayen' },
  { value: 'SWZ', label: '斯威士兰', tagName: '257-斯威士兰(SWZ)--Swaziland' },
  { value: 'SWE', label: '瑞典', tagName: '330-瑞典(SWE)--Sweden' },
  { value: 'CHE', label: '瑞士', tagName: '331-瑞士(CHE)--Switzerland' },
  { value: 'SYR', label: '叙利亚', tagName: '135-叙利亚(SYR)--Syrian Arab Republic' },
  { value: 'TJK', label: '塔吉克斯坦', tagName: '147-塔吉克斯坦(TJK)--Tajikistan' },
  { value: 'THA', label: '泰国', tagName: '136-泰国(THA)--Thailand' },
  { value: 'TGO', label: '多哥', tagName: '248-多哥(TGO)--Togo' },
  { value: 'TKL', label: '托克劳', tagName: '699-托克劳(TKL)--Tokelau' },
  { value: 'TON', label: '汤加', tagName: '614-汤加(TON)--Tonga' },
  { value: 'TTO', label: '特立尼达和多巴哥', tagName: '442-特立尼达和多巴哥(TTO)--Trinidad and Tobago' },
  { value: 'ARE', label: '阿联酋', tagName: '138-阿联酋(ARE)--United Arab Emirates (the)' },
  { value: 'TUN', label: '突尼斯', tagName: '249-突尼斯(TUN)--Tunisia' },
  { value: 'TUR', label: '土耳其', tagName: '137-土耳其(TUR)--Turkey' },
  { value: 'TKM', label: '土库曼斯坦', tagName: '148-土库曼斯坦(TKM)--Turkmenistan' },
  { value: 'TCA', label: '特克斯和凯科斯群岛', tagName: '443-特克斯和凯科斯群岛(TCA)--Turks and Caicos Islands (the)' },
  { value: 'TUV', label: '图瓦卢', tagName: '619-图瓦卢(TUV)--Tuvalu' },
  { value: 'UGA', label: '乌干达', tagName: '250-乌干达(UGA)--Uganda' },
  { value: 'UKR', label: '乌克兰', tagName: '347-乌克兰(UKR)--Ukraine' },
  { value: 'MKD', label: '马其顿', tagName: '354-马其顿(MKD)--Macedonia (the former Yugoslav Republic of)' },
  { value: 'EGY', label: '埃及', tagName: '215-埃及(EGY)--Egypt' },
  { value: 'GBR', label: '英国', tagName: '303-英国(GBR)--United Kingdom of Great Britain and Northern Ireland (the)' },
  { value: 'GGY', label: '根西岛', tagName: '399-根西岛(GGY)--Guernsey' },
  { value: 'JEY', label: '泽西岛', tagName: '399-泽西岛(JEY)--Jersey' },
  { value: 'IMN', label: '马恩岛', tagName: '399-马恩岛(IMN)--Isle of Man' },
  { value: 'TZA', label: '坦桑尼亚', tagName: '247-坦桑尼亚(TZA)--Tanzania, United Republic of' },
  { value: 'USA', label: '美国', tagName: '502-美国(USA)--United States of America (the)' },
  { value: 'VIR', label: '美属维尔京群岛', tagName: '499-美属维尔京群岛(VIR)--Virgin Islands (U.S.)' },
  { value: 'BFA', label: '布基纳法索', tagName: '251-布基纳法索(BFA)--Burkina Faso' },
  { value: 'URY', label: '乌拉圭', tagName: '444-乌拉圭(URY)--Uruguay' },
  { value: 'UZB', label: '乌兹别克斯坦', tagName: '149-乌兹别克斯坦(UZB)--Uzbekistan' },
  { value: 'VEN', label: '委内瑞拉', tagName: '445-委内瑞拉(VEN)--Venezuela (Bolivarian Republic of)' },
  { value: 'WLF', label: '瓦利斯和富图纳', tagName: '625-瓦利斯和富图纳(WLF)--Wallis and Futuna' },
  { value: 'WSM', label: '萨摩亚', tagName: '617-萨摩亚(WSM)--Samoa' },
  { value: 'YEM', label: '也门', tagName: '139-也门(YEM)--Yemen' },
  { value: 'ZMB', label: '赞比亚', tagName: '253-赞比亚(ZMB)--Zambia' },
  { value: 'ZAS', label: '亚洲其他国家(地区)', tagName: '199-亚洲其他国家(地区)(ZAS)--Asia other' },
  { value: 'ZAO', label: '非洲其他国家(地区)', tagName: '299-非洲其他国家(地区)(ZAO)--Africa other' },
  { value: 'ZEU', label: '欧洲其他国家(地区)', tagName: '399-欧洲其他国家(地区)(ZEU)--Europe other' },
  { value: 'ZSA', label: '拉丁美洲其他国家(地区)', tagName: '499-拉丁美洲其他国家(地区)(ZSA)--South America other' },
  { value: 'ZNA', label: '北美洲其他国家(地区)', tagName: '599-北美洲其他国家(地区)(ZNA)--North America other' },
  { value: 'ZOC', label: '大洋洲其他国家(地区)', tagName: '699-大洋洲其他国家(地区)(ZOC)--Oceania other' },
  { value: 'CEU', label: '休达', tagName: '210-休达(CEU)--Ceuta' },
  { value: 'MEL', label: '梅利利亚', tagName: '256-梅利利亚(MEL)--Melilla' },
  { value: 'CAI', label: '加那利群岛', tagName: '207-加那利群岛(CAI)--Canary Islands' },
  { value: 'GAM', label: '盖比群岛', tagName: '604-盖比群岛(GAM)--Gambier Islands' },
  { value: 'SOC', label: '社会群岛', tagName: '612-社会群岛(SOC)--Society Islands' },
  { value: 'TUB', label: '土布艾群岛', tagName: '616-土布艾群岛(TUB)--Tubai Islands' },
  { value: 'MAI', label: '马克萨斯群岛', tagName: '605-马克萨斯群岛(MAI)--Marquesas Islands' },
  { value: 'TUA', label: '土阿莫土群岛', tagName: '615-土阿莫土群岛(TUA)--Tuamotu Islands ' },
  { value: 'ZZZ', label: '国(地)别不详', tagName: '701-国(地)别不详(ZZZ)--Countries(reg.) unknow' },
  { value: 'ZUN', label: '联合国及机构和国际组织', tagName: "702-联合国及机构和国际组织(ZUN)--UN and oth.int'l org" },
]);
