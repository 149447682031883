import { inject } from 'vue';
import {
  ElMessageBox, ElMessage,
} from 'element-plus';
import MyAxios from '../../utils/axios/index_v2';
import stepStatusAry from '../../configs/stepStatusAry';
import failedReason from '../../configs/failedReasonCode'; // 驳回代码

export default class ButtonFunc {
  /**
   * @param {Object} queryData  查询表数据
   * @param {Object} tableData  查询结果数据
   */
  constructor(queryData, tableData) {
    this.queryData = queryData;// 查询字段数据
    this.tableData = tableData; // 查询结果数据
    this.backQueryData = { ...queryData }; // 备份数据
    this.setFilterOfContainer = inject('setFilterOfContainer');
  }

  // 查询按钮事件（根据内容向后台服务器发送查询请求)
  submitFunc = () => {
    // 超级管理员方可发送查询
    if (!this.setFilterOfContainer.isLogin && this.setFilterOfContainer.visitAuth !== 15) {
      ElMessageBox.confirm('请登陆', {
        type: 'warning',
        showClose: false,
        confirmButtonText: '确定',
        closeOnClickModal: false, // 点击蒙层取消
        closeOnPressEscape: false, // 禁止按ESC
      });
    }
    if (Number(this.queryData.status) === 1) {
      // 待提交， 禁止提交查询
      ElMessageBox.alert('待提交状态不可查询', {
        type: 'warning',
        showClose: true,
        confirmButtonText: '确定',
      });
      return;
    }

    MyAxios().initAxios({
      url: '/audit_query',
      method: 'POST',
      data: {
        contactNumber: this.queryData.contactNumber,
        destCountry: this.queryData.destCountry,
        shipperName: this.queryData.shipperName,
        consigneeName: this.queryData.consigneeName,
        startDate: new Date(Number(this.queryData.orderDate[0])), // 从时间:00:00:00开始
        endDate: new Date(Number(this.queryData.orderDate[1])), // 结束时间需要加1天
        status: Number(this.queryData.status) > 1 ? Number(this.queryData.status) - 1 : Number(this.queryData.status),
        productName: this.queryData.productName,
      },
    }).then((res) => {
      if (res.code === 200) {
        this.tableData.splice(0, this.tableData.length);// 清空数据
        if (res.results === null) {
          return;
        }
        this.tableData.splice(0, this.tableData.length);// 清空数据
        this.washDataOfResults(res.results); // 整理数据
        Object.assign(this.tableData, res.results);// 重新赋值
      }
    }).catch(() => {
      ElMessage.error('连接失败');
    });
  };

  // 重置
  resetFunc = () => {
    Object.assign(this.queryData, this.backQueryData);
  }

  /**
   * 整理返回结果数据
   * @param {Object} results 后台返回数据对象
   */
  washDataOfResults = (results) => {
    results.forEach((item) => {
      // 保留2位浮点数
      item.netWt = item.netWt.toFixed(2);
      item.grossWt = item.grossWt.toFixed(2);
      // 将id 转为字符串
      item.id = item.id.toString();
      // 以状态值获取中文label
      item.stepState = stepStatusAry.stepStatusAry.find((v) => v.value === item.stepState.toString()).label;
      // 代码驳回原由转中文
      if (item.failedReason !== '') {
        item.failedReason = failedReason.parseFailedCode(item.failedReason.toString());
      } else {
        item.failedReason = '-';
      }
      // 报关授权代码
      let tempAuthorizedRes = '';
      if (item.authorized !== null) {
        item.authorized.forEach((element) => {
          tempAuthorizedRes += `${element.companyName}-${element.customsID}   `;
        });
        item.authorized = tempAuthorizedRes;
      } else {
        item.authorized = '-';
      }

      // 订单时间函数只取日期
      const rs = new Date(item.orderDate.replaceAll('-', '/').replaceAll('UTC', ''));// 将时间转换成safari识别格式
      item.orderDate = `${rs.getFullYear()}-${rs.getMonth() + 1}-${rs.getDate()}`;
      if (item.payDate !== '') {
        const rsPay = new Date(item.payDate.replaceAll('-', '/').replaceAll('UTC', ''));
        item.payDate = `${rsPay.getFullYear()}-${rsPay.getMonth() + 1}-${rsPay.getDate()} ${rsPay.getHours()}:${rsPay.getMinutes()}`;
      } else {
        item.payDate = '-';
      }
    });
  }
}
