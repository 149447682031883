<template>
  <div class="tableform">
    <el-table :data="tableDatas.tableData" :default-sort="{ prop: 'orderDate', order: 'descending' }"
      style="width: 100%" row-key="id" stripe border lazy>
      <el-table-column label="序列" width="50" type="index" />
      <el-table-column prop="orderDate" label="日期" width="99" sortable></el-table-column>
      <el-table-column prop="contractNum" label="合同号" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="factoryName" label="客户" width="150" show-overflow-tooltip sortable></el-table-column>
      <el-table-column prop="consigneeName" label="收货人" width="150" show-overflow-tooltip sortable></el-table-column>
      <el-table-column prop="productName" label="品名" min-width="180" show-overflow-tooltip></el-table-column>
      <el-table-column prop="destCountry" label="目的地" width="90" show-overflow-tooltip sortable></el-table-column>
      <el-table-column prop="netWt" label="净重(KG)" width="90"></el-table-column>
      <el-table-column prop="grossWt" label="毛重(KG)" width="90"></el-table-column>
      <el-table-column prop="carton" label="箱数" width="90"></el-table-column>
      <el-table-column prop="stepState" label="当前状态" width="100" show-overflow-tooltip sortable></el-table-column>
      <el-table-column prop="mobile" label="联系方式" width="130"></el-table-column>
      <el-table-column prop="authorized" label="报关授权代码" width="130" show-overflow-tooltip></el-table-column>
      <el-table-column prop="payDate" label="付款时间" width="130" sortable></el-table-column>
      <el-table-column prop="failedReason" label="驳回原由" width="130" sortable></el-table-column>
      <el-table-column prop="op" label="操作" width="120" fixed="right">
        <template v-slot="props">
          <div class="buttonBody">
            <span @click="auditRowButtonClass.agreeClickFunc(props.row.id, props.row.stepState)">通过</span>
            <span @click="auditRowButtonClass.disagreeClickFunc(props.row.id, props.row.stepState)">拒绝</span>
            <span @click="auditRowButtonClass.showClickFunc(props.row.id)">查看</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 弹出详情框 -->
  <DialogDetail v-if="tableDatas.maskDivState.status" :maskDivStateFather="tableDatas.maskDivState" />
</template>
<script>
import { reactive } from 'vue';
import DialogDetail from '../customsdocs/DialogDetail.vue'; // 详情展示
import AuditRowButtonClass from './auditRowButtonClass';

export default {
  name: 'AuditTableForm',
  props: [// 父页传递过来的数据
    'tableData',
    'maskDivState',
  ],
  components: {
    DialogDetail,
  },
  setup(props) {
    const tableDatas = reactive({
      tableData: props.tableData,
      maskDivState: props.maskDivState,
    });

    // 实例点击事件函数
    const auditRowButtonClass = new AuditRowButtonClass(tableDatas.maskDivState);
    return {
      tableDatas,
      auditRowButtonClass,
    };
  },

};
</script>
<style lang="scss" scoped>
.tableform {
  padding: $base;
  height: 100% !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;

  // 隐藏侧边滚动条
  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */

  :deep(.el-table) {
    border: 1px solid rgba($hovefontcolor, 0.5);
    border-radius: 5px;
    overflow: hidden;

    &:hover {
      border-color: $hovefontcolor;
    }
  }

  //下拉显示内容样式
  :deep(.el-table__cell.el-table__expanded-cell) {
    background-color: var(--el-color-primary-light-9);
    padding: $base;
    border: 1px solid var(--el-border-color-base);
    animation: 0.5s opacityClass 0s ease;
    -webkit-animation: 0.5s opacityClass 0s ease;
    -moz-animation: 0.5s opacityClass 0s ease;

    &:hover {
      border: 1px solid var(--el-border-color-hover);
      background-color: var(--el-color-primary-light-8) !important;
    }

    // }
    // :deep(.expanded + tr) {

    .el-form-item__label {
      color: $hovefontcolor;
      text-align: right;
      min-width: 80px;
      line-height: 30px;
    }

    .el-form-item__content {
      color: $dark;
      line-height: 30px;
    }
  }

  :deep(.el-table tr) {
    // background: #eee;
  }

  :deep(.el-table__body-wrapper) {
    // 定位table前设置父元素高度方可生效
    height: 336px;
  }

  :deep(.el-table__header) {
    tr th {
      &:last-child {
        text-align: center;
      }
    }
  }

  :deep(.el-table__body) {
    >tbody>tr>td {
      &:last-child {
        .cell {
          // text-align: center;
          // .slotbox {
          // 	background: red;
          // }
        }
      }
    }
  }

  @keyframes opacityClass {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes opacityClass {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes opacityClass {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  :deep(.el-table__header) {
    th {
      background-color: var(--el-color-primary-light-9);
      color: $dark;
    }
  }

  :deep(.el-table td.el-table__cell div) {
    white-space: pre;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  .loadingFont {
    width: 100%;
    margin: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    background: white;
  }

  .paied {
    color: red;
  }
}

:deep(.el-dialog__header) {
  border-bottom: 1px solid gray;
}

:deep(.el-dialog__body) {
  padding: 10px;
}

:deep(.el-dialog__body) {
  .el-table__row {
    >td {
      .cell {
        height: 52px;

        .el-form-item__error {
          padding-top: 0px;
        }

        span {
          line-height: 52px;
        }

        .optionsBtnClass {
          span {
            line-height: 42px;
          }
        }
      }
    }
  }
}

.buttonBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: #67c23a;
    }
  }
}
</style>
